/* .cart{
    overflow: auto;
} */

.cart-item {
    overflow: auto;
    /* display: flex;
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* gap: 30px;  */
    max-height: 100%;
    height: 100%;
}

.cart-item-inner {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 975px;
}

.cart-item-inner img {
    width: 100px !important;
    height: 100px !important;
    margin-right: 20px;
}

.cart-item-title {
    width: 180px !important;
}

.cart-item-amount {
    padding: 0px 5px;
    font-size: 1.1rem;
}

.amount-btn {
    font-size: 1.55rem !important;
    position: relative;
    bottom: 3px;
}

.cart-item-title,
.cart-item-price {
    font-size: 1.1rem;
    width: 175px;
}

.cart-item-price {
    height: 100px !important;
}

.cart-item-amount {
    text-align: center;
}

.cart-item-remove-btn {
    height: 60px;
    width: 60px;
    margin-left: auto;
    font-size: 1.3rem !important;
}