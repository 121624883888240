/* Admin container component */
.admin-menu {
    height: 100vh !important;
    width: 20%;
}

.administration-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.administration-menu-close {
    display: none;
}

.admin-content {
    width: 80%;
    max-height: 100vh;
    overflow-y: auto;
}

.admin-content-inner {
    width: 100%;
    /* for showcase only */
    /* height: 200vh; */
}

.side-menu-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-res-btn {
    font-size: 1.5rem !important;
}

.admin-res-btn.side-menu {
    display: none;
}

.admin-res-btn.home:focus,
.admin-res-btn.home:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

/* .admin-logo-cont{
    height: 80px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}

.admin-logo-cont img{
    height: 90%;
} */

@media screen and (max-width: 1024px) {
    .admin-menu {
        height: 100vh !important;
        width: 100%;
        position: absolute;
        z-index: 100;
        transition: .35s;
        top: 0;
        left: -100%;
    }

    .admin-menu.open {
        left: 0%;
    }

    .administration-menu-close {
        display: block;
        font-size: 1.5rem;
        padding: 0;
        border: none;
        background-color: transparent;
        color: #f2f2f2;
    }

    .administration-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-content {
        width: 100%;
        max-height: 100vh;
        overflow-y: auto;
    }

    .side-menu-icon-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-res-btn.side-menu {
        display: block;
    }
}

/* Specific panels */

/* All categories panel */
.category-asitem {
    width: 450px;
    /* padding: 10px !important; */
}

/* All products panel */
.all-items-cont {
    overflow-x: auto;
}

.all-items-cont::-webkit-scrollbar {
    width: 5px;
}

.all-items-cont::-webkit-scrollbar-track {
    background: #f2f2f2;
}

.all-items-cont::-webkit-scrollbar-thumb {
    background: #c5c5c5;
}

.product-asitem,
.product-asitem-details {
    min-width: 930px;
}

.product-asitem-details {
    padding-left: 130px !important;
}

.product-asitem-image {
    height: 75px;
    width: 75px;
}

.product-asitem-icon-btn {
    font-size: 1.2rem !important;
}

.published-btn {
    min-width: 125px;
}

.premium-btn {
    font-size: 1.35rem !important;
    margin-right: 25px !important;
}

.product-asitem-title {
    max-width: 170px;
}

.category-asitem-title {
    width: 300px;
}

/* Add Product panel */
.combinations-cont {
    max-height: 500px;
    overflow: auto;
}

.combination {
    cursor: pointer;
}

.active-combination {
    background-color: rgba(200, 200, 255, 0.3);
}

.add-product-input {
    border: 0px;
    background: transparent;
    outline: none;
    border-bottom: 1px solid #252525;
    width: 50%;
    padding: 2px;
}

.add-product-input-dimension {
    border: 0px;
    background: transparent;
    outline: none;
    border-bottom: 1px solid #252525;
    width: 100px;
    padding: 2px;
}

.add-product-input-dimension:not(:last-of-type) {
    margin-bottom: 20px !important;
}