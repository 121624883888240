.top-nav {
    width: 100%;
    height: 35px;
    background-color: #e5e5e5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 50px;
}

.top-nav p {
    font-weight: 400 !important;
    opacity: .75;
}

.app-navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90px;
    padding: 0px 50px;
    gap: 80px;
    border-bottom: 1px solid #e5e5e5;
}

.app-nav-logo {
    font-family: 'Cormorant Garamond', serif !important;
    font-size: 1.1rem;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.15rem;
    height: 100%;
    width: 200px;
    padding: 0px 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.app-nav-logo img {
    width: 70%;
}

.toggle-container {
    display: none;
}

.app-nav-link-cont {
    color: #252525;
    text-decoration: none !important;
}

.app-nav-link{
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: 300 !important;
    cursor: pointer;
}

.cart .app-nav-link {
    font-size: 1.5rem !important;
    position: relative;
    bottom: 2.5px;
}

.cart .cart-count {
    position: absolute;
    top: 0px;
    right: -12.5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 0.75rem;
}

.cart-count span{
    position: relative;
    top: 1px;
    right: 0px;
}

.app-navbar-links {
    flex-grow: 1;
    font-size: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

@media screen and (max-width: 1024px) {
    .top-nav {
        display: none;
    }

    .app-navbar {
        justify-content: space-between;
        padding: 0px 35px 0px 0px;
        gap: 0;
    }

    .user-role{
        display: none !important;
    }

    .app-nav-logo {
        font-family: 'Cormorant Garamond', serif !important;
        font-size: 1.1rem;
        text-align: center;
        letter-spacing: 1px;
        line-height: 1.15rem;
        height: 100%;
        width: 150px;
        padding: 0px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: white;
    }

    .toggle-container {
        display: block;
        font-size: 1.5rem;
    }

    .toggle-container.open {
        z-index: 101;
        color: #f2f2f2;
    }

    .app-navbar-links {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: -100%;
        transition: .35s;
        z-index: 100;
        background-color: #252525 !important;
    }

    .app-nav-link-cont {
        color: #f2f2f2 !important;
        text-decoration: none !important;
    }

    .app-nav-link {
        color: #f2f2f2;
        min-width: 50%;
        text-align: center;
        position: relative;
        text-decoration: none !important;
    }

    /* .app-nav-link::after {
        position: absolute;
        bottom: calc(50% + .75px);
        left: 0px;
        display: block;
        content: "";
        height: 1.5px;
        width: 40px;
        background-color: #f2f2f2;
    } */

    .app-navbar-links.open {
        top: 0%;
        transition: .35s;
    }
}