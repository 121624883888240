* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  background-color: #f2f2f2;
}

/* COMPONENTS */
@import './assets/styles/components/shared/navbar.css';
@import './assets/styles/components/shared/footer.css';
@import './assets/styles/components/shared/initloader.css';
@import './assets/styles/components/shared/dropdown.css';
@import './assets/styles/components/newsletter.css';
@import './assets/styles/components/productsfilters.css';
@import './assets/styles/components/productgalery.css';

/* PAGES */
@import './assets/styles/pages/home.css';
@import './assets/styles/pages/products.css';
@import './assets/styles/pages/productdetails.css';
@import './assets/styles/pages/login.css';
@import './assets/styles/pages/register.css';
@import './assets/styles/pages/cart.css';
@import './assets/styles/pages/adminpanel.css';
@import './assets/styles/pages/contact.css';
@import './assets/styles/pages/locations.css';

/* GLOBAL */
@import './assets/styles/typography.css';
@import './assets/styles/layouts.css';

/* OVERRIDES */
@import './assets/styles/bootstrapoverrides.css';