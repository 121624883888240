.newsletter-container {
    min-height: 40vh;
    background-image: url('../../images/tiles/tile1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.newsletter-overlay {
    width: 100%;
    min-height: 40vh;
    color: white;
    background-color: rgba(25, 25, 25, .5);
}

.newsletter-title{
    font-size: 2.75rem !important;
}
.newsletter-subtitle{
    font-size: 1.75rem !important;
}

.newsletter-form input[type='text']{
    outline: none;
    border: none;
    border-bottom: 1px solid #f2f2f2;
    width: 50%;
    color: #f2f2f2;
    padding: 5px;
    font-size: 1.1rem;
}

.newsletter-form input[type='submit']{
    color: #252525;
    font-size: 1.1rem;
}

@media screen and (max-width: 1024px) {
    .newsletter-title{
        font-size: 1.75rem !important;
    }

    .newsletter-form input[type='submit']{
        font-size: .9rem;
    }
    .newsletter-form input[type='text']{
        width: 65%;
    }
}