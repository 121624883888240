.dropdown-icon{
    position: relative;
    bottom: 2px;
    transition: .35s;
}

.dropdown-icon.open{
    transform: rotate(180deg);
    transition: .35s;
}

.dropdown-icon.sublink{
    transform: rotate(270deg);
}