.products-filters-container {
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: -400px;
    width: 400px;
    background-color: #f2f2f2;
    z-index: 10;
    transition: .35s;
}

@media screen and (max-width: 1024px) {
    .products-filters-container {
        left: -100%;
        width: 100%;
    }
}

.products-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    z-index: 9;
    transition: .5s;
    background-color: rgba(25, 25, 25, 0.65);
}

.products-filters-container.open {
    left: 0px;
    transition: .5s;
}

/* width */
.products-filters-container::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.products-filters-container::-webkit-scrollbar-track {
    background: #f2f2f2;
}

/* Handle */
.products-filters-container::-webkit-scrollbar-thumb {
    background: #c5c5c5;
}

.products-overlay.open {
    left: 0;
    transition: .5s;
}

.filter-heading {
    font-size: 1.35rem;
}

.filter-title {
    padding: 10px 0px 5px 0px;
    font-size: 1.1rem;
}

.color-option {
    height: 42.5px;
    width: 42.5px;
    cursor: pointer;
}

.color-option-white {
    background-color: white;
}

.color-option-black {
    background-color: black;
}

.color-option-red {
    background-color: crimson;
}

.color-option-green {
    background-color: green;
}

.color-option-blue {
    background-color: dodgerblue;
}

.color-option-yellow {
    background-color: yellow;
}

.color-option-purple {
    background-color: purple;
}

.color-option-brown {
    background-color: brown;
}

.color-option-orange {
    background-color: orange;
}

.color-option-gray {
    background-color: gray;
}

.color-option.active {
    height: 42.5px;
    width: 42.5px;
    border: 2.5px solid dodgerblue !important;
}

.price-input {
    /* background-color: rgba(0, 0, 0, 0.05); */
    background-color: rgba(255,255,255,.25);
    border: none !important;
    border-bottom: 2px solid #454545 !important;
    outline: none !important;
    padding: 2.5px 0px 2.5px 6.5px;
    width: 70px;
}