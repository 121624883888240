* {
    font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500 !important;
    /* font-family: 'Montserrat', sans-serif !important;  */
}

p {
    padding: 0;
    margin: 0 !important;
}

.navigation-map-text{
    font-size: 0.90rem;
    opacity: .7;
}

.txt-sm{
    font-size: .9rem;
}