.galery-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    z-index: 9;
    transition: .5s;
    background-color: rgba(25, 25, 25, 0.95);
}

.galery-overlay.open {
    left: 0;
    transition: .5s;
}

.galery-main-image {
    width: 700px;
    height: 90%;
    /* object-fit: cover; */
    
    object-position: center !important;
    /* opacity: 0; */
}

.galery-main-image.open {
    animation: opacityAppear 1s;
    /* animation-delay: 0.5s; */
}

@keyframes opacityAppear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}