.home-hero-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slider {
    overflow: hidden;
    position: relative;
}

.slider.backwards .slide-track {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400% !important;
    transition: .35s;
}

.slider.backwards .slide-track.active-0 {
    bottom: 0;
    left: 0;
    right: 0;
}

.slider.backwards .slide-track.active-1 {
    bottom: -100%;
    left: 0;
    right: 0;
}

.slider.backwards .slide-track.active-2 {
    bottom: -200%;
    left: 0;
    right: 0;
}

.slider.backwards .slide-track.active-3 {
    bottom: -300%;
    left: 0;
    right: 0;
}

/* left (top) slider */
.slider.forward .slide-track {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 400% !important;
    transition: .35s;
}

.slider.forward .slide-track.active-0 {
    top: 0;
    left: 0;
    right: 0;
}

.slider.forward .slide-track.active-1 {
    top: -100%;
    left: 0;
    right: 0;
}

.slider.forward .slide-track.active-2 {
    top: -200%;
    left: 0;
    right: 0;
}

.slider.forward .slide-track.active-3 {
    top: -300%;
    left: 0;
    right: 0;
}

.slider .slide-track .slide {
    position: relative;
    height: 25%;
}

.catalog-container {
    height: 75px;
}

.slide img {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 2.5px;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center !important;
}

.slide .premium-badge {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    padding: 10px 12.5px;
    background-color: #252525;
    /* font-size: 1rem; */
    background-color: rgba(25, 25, 25, 0.5);
    font-size: .8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.slider .slide .premium-badge svg {
    /* transform: translateY(25px) !important; */
    position: relative;
    top: -2px !important;
    font-size: .95rem;
    /* color: gold; */
}

.slider .slide .slide-overlay {
    background-color: rgba(25, 25, 25, 0.5);
    opacity: 0;
    transition: .35s;
}

.slider .slide:first-of-type:hover .slide-overlay {
    opacity: 1;
}

.slider .slide:nth-of-type(2):hover .slide-overlay {
    opacity: 1;
}

.slider .slide:nth-of-type(3):hover .slide-overlay {
    opacity: 1;
}

.slider .slide:last-of-type:hover .slide-overlay {
    opacity: 1;
}

.slider-item-container {
    cursor: pointer;
    width: 100%;
    height: 25%;
    padding: 0px 2.5px 2.5px 2.5px;
    filter: brightness(.5);
    transition: .35s;
}

.slider-item-container.active {
    filter: brightness(1);
    transition: .35s;
}

.slider-item-container:last-of-type {
    padding: 0px 2.5px 0px 2.5px;
}

.slider-item-container:last-of-type {
    padding: 0px 2.5px 0px 2.5px;
}

.slider-item-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center !important;
}

/* Grid galery */

.container {
    /* height: 100%; */
    padding: 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 2.5px;
    grid-auto-flow: row;
    grid-template-areas:
        "top-main top-main top-secondary top-secondary"
        "top-main top-main top-ternary-1 top-ternary-2"
        "bottom-ternary-1 bottom-ternary-2 bottom-main bottom-main"
        "bottom-secondary bottom-secondary bottom-main bottom-main";
}

.min-height-box {
    height: max(120px, calc(90vh / 4));
}

.top-main {
    grid-area: top-main;
    background: url('https://images.pexels.com/photos/6315797/pexels-photo-6315797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.top-secondary {
    grid-area: top-secondary;
    background: url('../../images/tiles/tile2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.top-ternary-1 {
    grid-area: top-ternary-1;
    background: url('../../images/tiles/tile3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.top-ternary-2 {
    grid-area: top-ternary-2;
    background: url('../../images/tiles/tile4.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bottom-main {
    grid-area: bottom-main;
    background: url('https://images.pexels.com/photos/6315797/pexels-photo-6315797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bottom-ternary-2 {
    grid-area: bottom-ternary-2;
    background: url('../../images/tiles/tile1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bottom-ternary-1 {
    grid-area: bottom-ternary-1;
    background: url('../../images/tiles/tile2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bottom-secondary {
    grid-area: bottom-secondary;
    background: url('../../images/tiles/tile3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.h-100vh-header.slider {
    height: calc(100vh - 200px) !important;
}

.h-100vh-header.home-hero-middle {
    height: calc(100vh - 200px) !important;
}

@media screen and (max-width: 1024px) {
    .home-hero-middle {
        flex-direction: row;
    }

    .slider-item-container {
        width: 25%;
        height: 100%;
        padding: 2.5px;
    }

    .container {
        gap: 0;
    }

    .slider-item-container {
        padding: 2.5px 0px 2.5px 2.5px !important;
    }

    .slider-item-container:first-of-type {
        /* padding: 2.5px 0px 2.5px 2.5px !important; */
        padding-left: 0px !important;
    }

    .h-100vh-header.home-hero-middle {
        height: 140px !important;
    }

    .h-100vh-header.slider {
        height: calc(100vh - 90px - 140px - 75px) !important;
    }
}