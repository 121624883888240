.products-hero-right {
    background-image: url('../../images/tiles/tile2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px !important;
}

.products-hero-left {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.open-filters-btn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .35s;
}

.open-filters-btn:hover {
    transition: .35s;
    cursor: pointer;
    transform: scale(1.1);
}

.tile-card {
    border-radius: 0% !important;
    border-collapse: collapse;
}

.tile-card.tile-card-premium {
    background-color: rgba(255, 215, 0, 0.025) !important;
}

.tile-card .card-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center !important;
}

.tile-card-content {
    text-transform: capitalize;
}

.tile-title {
    font-weight: 900;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.tile-category {
    font-weight: 300;
}

.tile-price {
    font-size: 1.1rem;
}

.tile-price span {
    font-weight: 900;
}