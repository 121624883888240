/* .footer-bottom{
    background-color: #e5e5e5;
} */

.footer-bottom p {
    opacity: .75;
}

.site-map-link{
    padding: 5px !important;
    color: #252525;
}

.social-link{
    font-size: 1.8rem;
}

@media screen and (max-width: 1024px) {
    .fluid-50 img{
        width: 30% !important;
    }
}