/* containers */
.row {
    padding: 0;
    margin: 0;
}

/* colors */
.custom-bg-dark {
    background-color: #252525 !important;
}

.custom-bg-gray {
    background-color: #e5e5e5 !important;
}

.custom-bg-light {
    background-color: #f2f2f2 !important;
}

/* borders */
.custom-border-all {
    border: 1px solid #e5e5e5 !important;
}

.custom-border-all-dark {
    border: 1px solid #252525 !important;
}

.custom-border-bottom {
    border-bottom: 1px solid #e5e5e5 !important;
}

.custom-border-bottom-dark {
    border-bottom: 1px solid #898989 !important;
}

.custom-border-top {
    border-top: 1px solid #e5e5e5 !important;
}

.custom-border-top-dark {
    border-top: 1px solid #898989 !important;
}

.custom-border-left-dark {
    border-left: 1.5px solid #E9E9E9 !important;
}

.custom-border-right-white {
    border-right: 1px solid #f2f2f2;
}

/* buttons */
.btn {
    border-radius: 0 !important;
}

.btn-dark {
    background-color: #252525 !important;
}