.loader-container {
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.init-loader {
    animation: init 2.5s;
    font-family: 'Cormorant Garamond', serif !important;
    font-size: 1.1rem;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.15rem;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    position: relative;
}

.init-loader img{
    width: 100%;
}

@keyframes init {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}