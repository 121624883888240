.h-100vh-header {
    height: calc(100vh - 125px) !important;
}

.h-100vh-header-map {
    height: calc(96vh - 145.2px) !important;
}

.mh-100vh-header-map {
    min-height: calc(96vh - 145.2px) !important;
}

.mh-50vh-header-map {
    min-height: calc(65vh - 145.2px) !important;
}

.custom-container-md {
    width: 100%;
    padding: 0px 50px;
}

.w-md-50 {
    width: 50% !important;
}

.w-md-25 {
    width: 25% !important;
}

@media screen and (max-width: 1024px) {
    .h-100vh-header {
        height: calc(100vh - 90px) !important;
    }

    .w-md-50 {
        width: 100% !important;
    }

    .w-md-25 {
        width: 100% !important;
    }

    .custom-container-md {
        width: 100%;
        padding: 0px;
    }
}