.product-cover-image {
    width: 100%;
    height: calc(90vh);
    animation: appearfirst .75s;
    object-fit: cover;
    object-position: center !important;
}

.product-cover-image.hovered {
    animation: appearsecond .75s;
}

@keyframes appearfirst {
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
}

@keyframes appearsecond {
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
}

.product-details-title {
    font-size: 3.5rem;
}

.product-details-title span {
    opacity: .5;
}

.product-details-dimensions {
    font-size: 1.25rem;
}

.image-catalog .catalog-item {
    width: calc((100% / 6) - 5px);
    height: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    object-fit: cover;
    object-position: center !important;
}

.cart-amount {
    width: 100px;
}

@media screen and (max-width: 1024px) {
    .product-cover-image {
        height: 57vh;
    }

    .image-catalog .catalog-item {
        width: calc((100% / 5) - 2.5px);
        height: 100px;
        margin-right: 2.5px;
        margin-bottom: 2.5px;
    }

    .product-details-title {
        font-size: 2.25rem;
        /* margin-bottom: 50px; */
    }
}