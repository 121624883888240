.contact-hero-image{
    background: url('https://images.pexels.com/photos/5745183/pexels-photo-5745183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
    height: calc(96vh - 145.2px) !important;
}

@media screen and (max-width: 1024px) {
    .contact-hero-image{
        height: 40vh !important;
        margin-top: 25px !important;
    }
    
}